import type { RouteRecordRaw } from 'vue-router'

import PayoutLocationsList from '@/components/Panels/Finances/Payouts/PayoutLocationsList.vue'
import CardTransactionsList from '@/components/Sales/CardTransactionsList.vue'

import { Section } from './shared/constants'

const Blank = () => import('@/pages/Blank.vue')
const CreateFinanceAccount = () => import('@/pages/Finances/FinanceAccounts/FinanceAccountsCreate.vue')
const EditFinanceAccount = () => import('@/pages/Finances/FinanceAccounts/FinanceAccountsEdit.vue')
const FinanceAccountList = () => import('@/pages/Finances/FinanceAccounts/FinanceAccountsList.vue')
const InvoicesList = () => import('@/pages/Finances/Invoices/InvoicesList.vue')
const CreatePaymentMethodAccount = () =>
  import('@/pages/Finances/PaymentMethodAccounts/PaymentMethodAccountsCreate.vue')
const EditPaymentMethodAccount = () => import('@/pages/Finances/PaymentMethodAccounts/PaymentMethodAccountsEdit.vue')
const PaymentMethodAccountList = () => import('@/pages/Finances/PaymentMethodAccounts/PaymentMethodAccountsList.vue')

export const routeNames = {
  FINANCES_FINANCE_ACCOUNTS_LIST: 'FINANCES_FINANCE_ACCOUNTS_LIST',
  FINANCES_FINANCE_ACCOUNT_CREATE: 'FINANCES_FINANCE_ACCOUNT_CREATE',
  FINANCES_FINANCE_ACCOUNT_EDIT: 'FINANCES_FINANCE_ACCOUNT_EDIT',
  FINANCES_FINANCE_ACCOUNT_EDIT_REDIRECT: 'FINANCES_FINANCE_ACCOUNT_EDIT_REDIRECT',

  FINANCES_PAYMENT_METHOD_ACCOUNTS_LIST: 'FINANCES_PAYMENT_METHOD_ACCOUNTS_LIST',
  FINANCES_PAYMENT_METHOD_ACCOUNT_CREATE: 'FINANCES_PAYMENT_METHOD_ACCOUNT_CREATE',
  FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT: 'FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT',
  FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT_REDIRECT: 'FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT_REDIRECT',

  FINANCES_INVOICES_LIST: 'FINANCES_INVOICES_LIST',

  FINANCES_EXTERNAL_ORDER_PROVIDERS_LIST: 'FINANCES_EXTERNAL_ORDER_PROVIDERS_LIST',
  FINANCES_EXTERNAL_ORDER_PROVIDER_CREATE: 'FINANCES_EXTERNAL_ORDER_PROVIDER_CREATE',
  FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT: 'FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT',
  FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT_REDIRECT: 'FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT_REDIRECT',

  FINANCES_CREDIT_VOUCHERS_LIST: 'FINANCES_CREDIT_VOUCHERS_LIST',
  FINANCES_CREDIT_VOUCHER_EDIT: 'FINANCES_CREDIT_VOUCHER_EDIT',

  FINANCES_SALES: 'FINANCES_SALES',

  FINANCES_SALES_ORDER_EDIT: 'FINANCES_SALES_ORDER_EDIT',
  FINANCES_SALES_ORDER_EDIT_REDIRECT: 'FINANCES_SALES_ORDER_EDIT_REDIRECT',

  FINANCES_SALES_TRANSACTIONS_LIST: 'FINANCES_SALES_TRANSACTIONS_LIST',
  FINANCES_SALES_CASH_TRANSACTIONS_LIST: 'FINANCES_SALES_CASH_TRANSACTIONS_LIST',
  FINANCES_SALES_ORDERS_LIST: 'FINANCES_SALES_ORDERS_LIST',

  FINANCES_TRADING_DAYS_LIST: 'FINANCES_TRADING_DAYS_LIST',
  FINANCES_TRADING_DAY_EDIT: 'FINANCES_TRADING_DAY_EDIT',
  FINANCES_TRADING_DAY_EDIT_REDIRECT: 'FINANCES_TRADING_DAY_EDIT_REDIRECT',

  FINANCES_RETURN_ORDERS_LIST: 'FINANCES_RETURN_ORDERS_LIST',
  FINANCES_RETURN_ORDER_EDIT: 'FINANCES_RETURN_ORDER_EDIT',
  FINANCES_RETURN_ORDER_EDIT_REDIRECT: 'FINANCES_RETURN_ORDER_EDIT_REDIRECT',

  FINANCES_PAYOUTS: 'FINANCES_PAYOUTS',
  FINANCES_PAYOUT_DETAILS_LOCATIONS: 'FINANCES_PAYOUT_DETAILS_LOCATIONS',
  FINANCES_PAYOUT_DETAILS_TRANSACTIONS: 'FINANCES_PAYOUT_DETAILS_TRANSACTIONS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'finance-accounts',
    component: Blank,
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_FINANCE_ACCOUNTS_LIST,
        component: FinanceAccountList,
      },
      {
        path: 'create',
        name: routeNames.FINANCES_FINANCE_ACCOUNT_CREATE,
        component: CreateFinanceAccount,
      },
      {
        path: ':financeAccountId',
        name: routeNames.FINANCES_FINANCE_ACCOUNT_EDIT,
        props: true,
        component: EditFinanceAccount,
      },
      {
        path: ':financeAccountId/edit',
        name: routeNames.FINANCES_FINANCE_ACCOUNT_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.FINANCES_FINANCE_ACCOUNT_EDIT }),
      },
    ],
  },

  {
    path: 'payment-method-accounts',
    component: Blank,
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_PAYMENT_METHOD_ACCOUNTS_LIST,
        component: PaymentMethodAccountList,
      },
      {
        path: 'create',
        name: routeNames.FINANCES_PAYMENT_METHOD_ACCOUNT_CREATE,
        component: CreatePaymentMethodAccount,
      },
      {
        path: ':paymentMethodAccountId',
        name: routeNames.FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT,
        props: true,
        component: EditPaymentMethodAccount,
      },
      {
        path: ':paymentMethodAccountId/edit',
        name: routeNames.FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.FINANCES_PAYMENT_METHOD_ACCOUNT_EDIT }),
      },
    ],
  },

  {
    path: 'invoices',
    component: Blank,
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_INVOICES_LIST,
        component: InvoicesList,
      },
    ],
  },

  {
    path: 'external-order-providers',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_EXTERNAL_ORDER_PROVIDERS_LIST,
        component: () => import('@/pages/Finances/ExternalOrderProviders/ExternalOrderProvidersList.vue'),
      },
      {
        path: 'create',
        name: routeNames.FINANCES_EXTERNAL_ORDER_PROVIDER_CREATE,
        component: () => import('@/pages/Finances/ExternalOrderProviders/ExternalOrderProvidersCreate.vue'),
      },
      {
        path: ':externalOrderProviderId',
        name: routeNames.FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT,
        component: () => import('@/pages/Finances/ExternalOrderProviders/ExternalOrderProvidersEdit.vue'),
        props: true,
      },
      {
        path: ':externalOrderProviderId/edit',
        name: routeNames.FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.FINANCES_EXTERNAL_ORDER_PROVIDER_EDIT }),
      },
    ],
  },

  {
    path: 'credit-vouchers',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_CREDIT_VOUCHERS_LIST,
        component: () => import('@/pages/Finances/CreditVouchers/CreditVouchersList.vue'),
      },
      {
        path: ':creditVoucherId',
        name: routeNames.FINANCES_CREDIT_VOUCHER_EDIT,
        component: () => import('@/pages/Finances/CreditVouchers/CreditVouchersShow.vue'),
        props: true,
      },
    ],
  },
  {
    path: 'orders',
    redirect: { name: routeNames.FINANCES_SALES_ORDERS_LIST },
  },
  {
    path: 'sales',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_SALES,
        component: () => import('@/pages/Finances/Sales/index.vue'),
        redirect: () => ({ name: routeNames.FINANCES_SALES_ORDERS_LIST }),
        children: [
          {
            name: routeNames.FINANCES_SALES_ORDERS_LIST,
            path: 'orders',
            component: () => import('@/pages/Finances/Sales/Orders/OrderList.vue'),
          },
          {
            name: routeNames.FINANCES_SALES_TRANSACTIONS_LIST,
            path: 'card-transactions',
            component: () => import('@/pages/Finances/Sales/Transactions/CardTransactionsListPage.vue'),
          },
          {
            name: routeNames.FINANCES_SALES_CASH_TRANSACTIONS_LIST,
            path: 'cash-transactions',
            component: () => import('@/pages/Finances/Sales/Transactions/CashTransactionsListPage.vue'),
          },
        ],
      },
      {
        name: routeNames.FINANCES_SALES_ORDER_EDIT,
        path: 'orders/:orderId',
        component: () => import('@/pages/Finances/Sales/Orders/OrderDetails.vue'),
        props: true,
      },
      {
        path: 'orders/:orderId/edit',
        name: routeNames.FINANCES_SALES_ORDER_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.FINANCES_SALES_ORDER_EDIT }),
      },
    ],
  },
  {
    path: 'trading-days',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_TRADING_DAYS_LIST,
        component: () => import('@/pages/Finances/TradingDays/TradingDaysList.vue'),
      },
      {
        path: ':tradingDayId',
        name: routeNames.FINANCES_TRADING_DAY_EDIT,
        component: () => import('@/pages/Orders/TradingDays/EditTradingDay.vue'),
        props: true,
      },
      {
        path: ':tradingDayId/edit',
        name: routeNames.FINANCES_TRADING_DAY_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.FINANCES_TRADING_DAY_EDIT }),
      },
    ],
  },
  {
    path: 'return-orders',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: '',
        name: routeNames.FINANCES_RETURN_ORDERS_LIST,
        component: () => import('@/pages/Orders/ReturnOrders/ReturnOrderList.vue'),
      },
      {
        name: routeNames.FINANCES_RETURN_ORDER_EDIT,
        path: ':returnOrderId',
        component: () => import('@/pages/Orders/ReturnOrders/ReturnOrderDetails.vue'),
        props: true,
      },
      {
        path: ':returnOrderId/edit',
        name: routeNames.FINANCES_RETURN_ORDER_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.FINANCES_RETURN_ORDER_EDIT }),
      },
    ],
  },
  {
    path: 'payouts',
    props: true,
    component: () => import('@/pages/Finances/Payouts/PayoutDetails.vue'),
    meta: {
      section: Section.FINANCES,
    },
    children: [
      {
        path: 'locations/:year/:month/:payoutId/:acquirer',
        name: routeNames.FINANCES_PAYOUT_DETAILS_LOCATIONS,
        props: true,
        component: PayoutLocationsList,
      },
      {
        path: 'transactions/:year/:month/:payoutId/:acquirer',
        name: routeNames.FINANCES_PAYOUT_DETAILS_TRANSACTIONS,
        props: true,
        component: CardTransactionsList,
      },
    ],
  },
  {
    path: 'payouts/:year?/:month?',
    name: routeNames.FINANCES_PAYOUTS,
    props: true,
    component: () => import('@/pages/Finances/Payouts/PayoutsReport.vue'),
    meta: {
      section: Section.FINANCES,
    },
  },
]

export default routes
